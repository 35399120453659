import Mail from "lucide-solid/icons/mail-open";
import Send from "lucide-solid/icons/send";
import Inbox from "lucide-solid/icons/inbox";
import Cross from "lucide-solid/icons/x";
import { MetricCard } from "~/components/custom/metric-component";

export function MetricCards() {
  return (
    <section class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <MetricCard
        title="Total Sent"
        value="1,286"
        trend={12.5}
        icon={Send}
        color="bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400"
      />
      <MetricCard
        title="Delivered"
        value="1,254"
        trend={10.8}
        icon={Inbox}
        color="bg-amber-50 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400"
      />
      <MetricCard
        title="Opened"
        value="753"
        trend={-2.4}
        icon={Mail}
        color="bg-purple-50 dark:bg-purple-900/20 text-purple-600 dark:text-purple-400"
      />
      <MetricCard
        title="Bounced"
        value="753"
        trend={-2.4}
        icon={Cross}
        color="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400"
      />

      {/* <ProgressMeasure
              title="Verified"
              value="753"
              trend={-2.4}
              icon={Check}
              color="bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400"
            /> */}
    </section>
  );
}
