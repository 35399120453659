import { Index } from "solid-js";

interface Props {
  title: string;
  statList: Record<"key" | "value", string>[];
}

export function StatsCard(props: Props) {
  return (
    <div class="bg-white dark:bg-gray-800/40 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
      <h3 class="text-lg font-semibold mb-4 dark:text-white">
        Engagement by Time
      </h3>
      <div class="space-y-4">
        <Index each={props.statList}>
          {(stat) => (
            <li class="flex justify-between items-center">
              <span class="text-gray-600 dark:text-gray-300">{stat().key}</span>
              <span class="text-sm font-medium text-indigo-600 dark:text-indigo-400">
                {stat().value}
              </span>
            </li>
          )}
        </Index>
      </div>
    </div>
  );
}
