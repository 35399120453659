import { LineChart } from "~/components/solid-ui/chart";

export function EmailChart() {
  const chartData = {
    labels: [
      "Jan 22",
      "Feb 22",
      "Mar 22",
      "Apr 22",
      "May 22",
      "Jun 22",
      "Jul 22",
      "Aug 22",
      "Sep 22",
      "Oct 22",
      "Nov 22",
      "Dec 22",
    ],
    datasets: [
      {
        label: "Welcome Email",
        data: [
          2890, 2756, 3322, 3470, 3475, 3129, 3490, 2903, 2643, 2837, 2954,
          3239,
        ],
        fill: true,
      },
      {
        label: "Follow-up - 22/01/2022",
        data: [
          2338, 2103, 2194, 2108, 1812, 1726, 1982, 2012, 2342, 2473, 3848,
          3736,
        ],
        fill: true,
      },
    ],
  };
  return (
    <div class="h-64 max-w-full bg-white dark:bg-gray-800/40 backdrop-blur-xl rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700 cursor-crosshair">
      <LineChart data={chartData} />
    </div>
  );
}
